<template>
  <router-view></router-view>
</template>
<script setup lang="ts">
const url = window.location.href;
if (url.includes("inthebag.online")) {
  var link = document.querySelector("link[rel*='icon']");
  link.href =
    "https://appletservice.oss-accelerate.aliyuncs.com/checkout_merchant/b.png";
  document.title = "Inthebag";
} else {
  var link = document.querySelector("link[rel*='icon']");

  document.title = import.meta.env.VITE_APP_TITLE;
}
</script>
