export default {
    Shopping:{
        pay:'Pay',
        otherproduct:'other product',
        otherproducts:'other products',
        qty:'Qty',
        patpat:'Purchase on PatPat',
        each:'each',
        showall:'Show all',
        items:'items',
        showless:'Show less',
        subtotal:'Subtotal',
        totaldue:'Total due',
        cashier:'Cashier',
        and:'and'
    },
    LeftCard:{
        back:'Back',
        poweredby:'Powered by',
        terms:'Terms',
        privacy:'Privacy',
    },
    TopCard:{
        details:'Details',
        close:'Close'
    },
    PaymentMethod:{
        paymentmethod :'Payment method',
    },
    ContactInformation:{
        contactinformation:'Contact Information',
        shippingaddress:'Shipping address',
        country:'Country',
        address1:'Address line 1',
        address2:'Address line 2',
        name:'Name',
        billingaddress:'Billing address',
        phone:'Phone',
        email:'Email',
        FullName:'Full name',
        CPFNumber:'CPF number',
    },
    ButtunMessage:{
        processing:'Processing...',
        wechatPC:'Generate QR code for',
        pay:'Pay',
    },
    ErrorMessage:{
        confirm:'Confirm',
        FullNameerr:'Full Name is required.',
        CPFNumbererr:'CPF number is required.',
        Countryerr :"Country is required.",
        CPFIerr: "Please follow the format: XXX.XXX.XXX-XX or 11 digits without dashes.",
        CPFIVerr:'Invalid CPF number. Please check and re-enter.'
    },
    Pix:{
        ScanPix:'Scan or copy the code to pay with Pix',
        tip:'After submitting your order, scan the QR code with your banking app or copy the code to pay.',
        Scanapp:'Scan to pay with your bank app',
        pixcodetip:`Paste the Pix code under 'Pix copy and paste' in your internet banking portal or banking app.`,
        pix_centip:`After a successful payment, it still needs a confirmation from the provider This process may take up to several minutes.`,
        pix_copytip:'Or copy the code to pay',
        pleasehtml:'Please make a payment in'
    },
    Copy:{
        success:'Copied to clipboard',
        copy:'Copy'
    },
    // 新增24-06-06
    CardInformation:{
        CardInformation:'Card information',
        NameOnCard:'Name on card',
        Name:'Name',


        Nameerr:'Name on card is required.',
                
        Expirationerr:`Your card's expiration date is incomplete.`,
        InvalidExpiration: `Invalid expiration date.`,
        pastExpiration: `Your card's expiration date is in the past.`,

        Carderr:"Your card number is incomplete.",
        NumberErr:'Your card number is invalid',

        cvcerr: `Your card's security code is incomplete.`,
        InvalidCVCCode:'Invalid CVC Code.'
    },
    BillingAddress:{
        BillingAddress:'Billing address',
        BS:'Billing address is same as shipping',
        AL1:'Address line 1',
        AL2:'Address line 2',
        City:'City',
        Postalcode:'Postal code',
        State:'State',
        Email:'Email',
        Phone:'phone',
        Phonenumber:'Phone number'
    },
    ShippingAddress:{
        Name:'Name',
        AL1:'Address line 1',
        AL2:'Address line 2',
        City:'City',
        Postalcode:'Postal code',
        State:'State',
        ShippingAddress:' Shipping address'
    },
    FormErr:{
        l1:'Line1 is required.',
        l2:'Line2 is required.',
        Phoneerr:'Phone is required.',
        ie:'Invalid email.',
        er:'Email is required.',
        cr:'City is required.',
        pr:'Postal code is required.',
        sr:'State is required.',
        nr:'Name is required.',
        cor:'Country is required.'
    },
    // -------------------------------------------------------
    CountyCode:{
        CountyUrl:[
            {
                "id": 243,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/af.png",
                "englishName": "Afghanistan",
                "phoneAreaCode": "93",
                "code": "AF"
            },
            {
                "id": 1,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ax.png",
                "englishName": "Aland Islands",
                "phoneAreaCode": "358",
                "code": "AX"
            },
            {
                "id": 2,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/al.png",
                "englishName": "Albania",
                "phoneAreaCode": "355",
                "code": "AL"
            },
            {
                "id": 3,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/dz.png",
                "englishName": "Algeria",
                "phoneAreaCode": "213",
                "code": "DZ"
            },
            {
                "id": 4,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/as.png",
                "englishName": "American Samoa",
                "phoneAreaCode": "1",
                "code": "AS"
            },
            {
                "id": 5,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ad.png",
                "englishName": "Andorra",
                "phoneAreaCode": "376",
                "code": "AD"
            },
            {
                "id": 6,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ao.png",
                "englishName": "Angola",
                "phoneAreaCode": "244",
                "code": "AO"
            },
            {
                "id": 7,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ai.png",
                "englishName": "Anguilla",
                "phoneAreaCode": "1",
                "code": "AI"
            },
            {
                "id": 8,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/aq.png",
                "englishName": "Antarctica",
                "phoneAreaCode": "672",
                "code": "AQ"
            },
            {
                "id": 9,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ag.png",
                "englishName": "Antigua and Barbuda",
                "phoneAreaCode": "1",
                "code": "AG"
            },
            {
                "id": 10,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ar.png",
                "englishName": "Argentina",
                "phoneAreaCode": "54",
                "code": "AR"
            },
            {
                "id": 11,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/am.png",
                "englishName": "Armenia",
                "phoneAreaCode": "374",
                "code": "AM"
            },
            {
                "id": 12,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/aw.png",
                "englishName": "Aruba",
                "phoneAreaCode": "297",
                "code": "AW"
            },
            {
                "id": 13,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/au.png",
                "englishName": "Australia",
                "phoneAreaCode": "61",
                "code": "AU"
            },
            {
                "id": 14,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/at.png",
                "englishName": "Austria",
                "phoneAreaCode": "43",
                "code": "AT"
            },
            {
                "id": 15,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/az.png",
                "englishName": "Azerbaijan",
                "phoneAreaCode": "994",
                "code": "AZ"
            },
            {
                "id": 16,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bs.png",
                "englishName": "Bahamas",
                "phoneAreaCode": "1",
                "code": "BS"
            },
            {
                "id": 17,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bh.png",
                "englishName": "Bahrain",
                "phoneAreaCode": "973",
                "code": "BH"
            },
            {
                "id": 18,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bd.png",
                "englishName": "Bangladesh",
                "phoneAreaCode": "880",
                "code": "BD"
            },
            {
                "id": 19,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bb.png",
                "englishName": "Barbados",
                "phoneAreaCode": "1",
                "code": "BB"
            },
            {
                "id": 20,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/by.png",
                "englishName": "Belarus",
                "phoneAreaCode": "375",
                "code": "BY"
            },
            {
                "id": 21,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/be.png",
                "englishName": "Belgium",
                "phoneAreaCode": "32",
                "code": "BE"
            },
            {
                "id": 22,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bz.png",
                "englishName": "Belize",
                "phoneAreaCode": "501",
                "code": "BZ"
            },
            {
                "id": 23,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bj.png",
                "englishName": "Benin",
                "phoneAreaCode": "229",
                "code": "BJ"
            },
            {
                "id": 24,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bm.png",
                "englishName": "Bermuda",
                "phoneAreaCode": "1",
                "code": "BM"
            },
            {
                "id": 25,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bt.png",
                "englishName": "Bhutan",
                "phoneAreaCode": "975",
                "code": "BT"
            },
            {
                "id": 26,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bo.png",
                "englishName": "Bolivia, Plurinational State of",
                "phoneAreaCode": "591",
                "code": "BO"
            },
            {
                "id": 27,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bq.png",
                "englishName": "Bonaire, Saint Eustatius and Saba",
                "phoneAreaCode": "599",
                "code": "BQ"
            },
            {
                "id": 28,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ba.png",
                "englishName": "Bosnia and Herzegovina",
                "phoneAreaCode": "387",
                "code": "BA"
            },
            {
                "id": 29,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bw.png",
                "englishName": "Botswana",
                "phoneAreaCode": "267",
                "code": "BW"
            },
            {
                "id": 30,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bv.png",
                "englishName": "Bouvet Island",
                "phoneAreaCode": "47",
                "code": "BV"
            },
            {
                "id": 31,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/br.png",
                "englishName": "Brazil",
                "phoneAreaCode": "55",
                "code": "BR"
            },
            {
                "id": 43,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/io.png",
                "englishName": "British Indian Ocean Territory",
                "phoneAreaCode": "246",
                "code": "IO"
            },
            {
                "id": 32,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bn.png",
                "englishName": "Brunei Darussalam",
                "phoneAreaCode": "673",
                "code": "BN"
            },
            {
                "id": 33,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bg.png",
                "englishName": "Bulgaria",
                "phoneAreaCode": "359",
                "code": "BG"
            },
            {
                "id": 34,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bf.png",
                "englishName": "Burkina Faso",
                "phoneAreaCode": "226",
                "code": "BF"
            },
            {
                "id": 35,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bi.png",
                "englishName": "Burundi",
                "phoneAreaCode": "257",
                "code": "BI"
            },
            {
                "id": 36,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/kh.png",
                "englishName": "Cambodia",
                "phoneAreaCode": "855",
                "code": "KH"
            },
            {
                "id": 37,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cm.png",
                "englishName": "Cameroon",
                "phoneAreaCode": "237",
                "code": "CM"
            },
            {
                "id": 38,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ca.png",
                "englishName": "Canada",
                "phoneAreaCode": "1",
                "code": "CA"
            },
            {
                "id": 39,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cv.png",
                "englishName": "Cape Verde",
                "phoneAreaCode": "238",
                "code": "CV"
            },
            {
                "id": 40,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ky.png",
                "englishName": "Cayman Islands",
                "phoneAreaCode": "1",
                "code": "KY"
            },
            {
                "id": 41,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cf.png",
                "englishName": "Central African Republic",
                "phoneAreaCode": "236",
                "code": "CF"
            },
            {
                "id": 42,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/td.png",
                "englishName": "Chad",
                "phoneAreaCode": "235",
                "code": "TD"
            },
            {
                "id": 44,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cl.png",
                "englishName": "Chile",
                "phoneAreaCode": "56",
                "code": "CL"
            },
            {
                "id": 45,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cn.png",
                "englishName": "China",
                "phoneAreaCode": "86",
                "code": "CN"
            },
            {
                "id": 46,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cx.png",
                "englishName": "Christmas Island",
                "phoneAreaCode": "61",
                "code": "CX"
            },
            {
                "id": 47,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cc.png",
                "englishName": "Cocos (Keeling) Islands",
                "phoneAreaCode": "61",
                "code": "CC"
            },
            {
                "id": 48,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/co.png",
                "englishName": "Colombia",
                "phoneAreaCode": "57",
                "code": "CO"
            },
            {
                "id": 49,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/km.png",
                "englishName": "Comoros",
                "phoneAreaCode": "269",
                "code": "KM"
            },
            {
                "id": 50,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cg.png",
                "englishName": "Congo",
                "phoneAreaCode": "242",
                "code": "CG"
            },
            {
                "id": 51,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cd.png",
                "englishName": "Congo, the Democratic Republic of the",
                "phoneAreaCode": "243",
                "code": "CD"
            },
            {
                "id": 52,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ck.png",
                "englishName": "Cook Islands",
                "phoneAreaCode": "682",
                "code": "CK"
            },
            {
                "id": 53,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cr.png",
                "englishName": "Costa Rica",
                "phoneAreaCode": "506",
                "code": "CR"
            },
            {
                "id": 54,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ci.png",
                "englishName": "Côte d'Ivoire",
                "phoneAreaCode": "225",
                "code": "CI"
            },
            {
                "id": 55,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/hr.png",
                "englishName": "Croatia",
                "phoneAreaCode": "385",
                "code": "HR"
            },
            {
                "id": 56,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cw.png",
                "englishName": "Curaçao",
                "phoneAreaCode": "599",
                "code": "CW"
            },
            {
                "id": 57,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cy.png",
                "englishName": "Cyprus",
                "phoneAreaCode": "357",
                "code": "CY"
            },
            {
                "id": 58,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/cz.png",
                "englishName": "Czech Republic",
                "phoneAreaCode": "420",
                "code": "CZ"
            },
            {
                "id": 59,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/dk.png",
                "englishName": "Denmark",
                "phoneAreaCode": "45",
                "code": "DK"
            },
            {
                "id": 60,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/dj.png",
                "englishName": "Djibouti",
                "phoneAreaCode": "253",
                "code": "DJ"
            },
            {
                "id": 61,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/dm.png",
                "englishName": "Dominica",
                "phoneAreaCode": "1",
                "code": "DM"
            },
            {
                "id": 62,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/do.png",
                "englishName": "Dominican Republic",
                "phoneAreaCode": "1",
                "code": "DO"
            },
            {
                "id": 63,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ec.png",
                "englishName": "Ecuador",
                "phoneAreaCode": "593",
                "code": "EC"
            },
            {
                "id": 64,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/eg.png",
                "englishName": "Egypt",
                "phoneAreaCode": "20",
                "code": "EG"
            },
            {
                "id": 65,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sv.png",
                "englishName": "El Salvador",
                "phoneAreaCode": "503",
                "code": "SV"
            },
            {
                "id": 66,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gq.png",
                "englishName": "Equatorial Guinea",
                "phoneAreaCode": "240",
                "code": "GQ"
            },
            {
                "id": 67,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/er.png",
                "englishName": "Eritrea",
                "phoneAreaCode": "291",
                "code": "ER"
            },
            {
                "id": 68,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ee.png",
                "englishName": "Estonia",
                "phoneAreaCode": "372",
                "code": "EE"
            },
            {
                "id": 69,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/et.png",
                "englishName": "Ethiopia",
                "phoneAreaCode": "251",
                "code": "ET"
            },
            {
                "id": 70,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/fk.png",
                "englishName": "Falkland Islands (Malvinas)",
                "phoneAreaCode": "500",
                "code": "FK"
            },
            {
                "id": 71,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/fo.png",
                "englishName": "Faroe Islands",
                "phoneAreaCode": "298",
                "code": "FO"
            },
            {
                "id": 72,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/fj.png",
                "englishName": "Fiji",
                "phoneAreaCode": "679",
                "code": "FJ"
            },
            {
                "id": 73,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/fi.png",
                "englishName": "Finland",
                "phoneAreaCode": "358",
                "code": "FI"
            },
            {
                "id": 74,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/fr.png",
                "englishName": "France",
                "phoneAreaCode": "33",
                "code": "FR"
            },
            {
                "id": 75,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gf.png",
                "englishName": "French Guiana",
                "phoneAreaCode": "594",
                "code": "GF"
            },
            {
                "id": 76,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pf.png",
                "englishName": "French Polynesia",
                "phoneAreaCode": "689",
                "code": "PF"
            },
            {
                "id": 77,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tf.png",
                "englishName": "French Southern Territories",
                "phoneAreaCode": "262",
                "code": "TF"
            },
            {
                "id": 78,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ga.png",
                "englishName": "Gabon",
                "phoneAreaCode": "241",
                "code": "GA"
            },
            {
                "id": 79,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gm.png",
                "englishName": "Gambia",
                "phoneAreaCode": "220",
                "code": "GM"
            },
            {
                "id": 80,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ge.png",
                "englishName": "Georgia",
                "phoneAreaCode": "995",
                "code": "GE"
            },
            {
                "id": 81,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/de.png",
                "englishName": "Germany",
                "phoneAreaCode": "49",
                "code": "DE"
            },
            {
                "id": 82,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gh.png",
                "englishName": "Ghana",
                "phoneAreaCode": "233",
                "code": "GH"
            },
            {
                "id": 83,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gi.png",
                "englishName": "Gibraltar",
                "phoneAreaCode": "350",
                "code": "GI"
            },
            {
                "id": 84,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gr.png",
                "englishName": "Greece",
                "phoneAreaCode": "30",
                "code": "GR"
            },
            {
                "id": 85,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gl.png",
                "englishName": "Greenland",
                "phoneAreaCode": "299",
                "code": "GL"
            },
            {
                "id": 86,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gd.png",
                "englishName": "Grenada",
                "phoneAreaCode": "1",
                "code": "GD"
            },
            {
                "id": 87,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gp.png",
                "englishName": "Guadeloupe",
                "phoneAreaCode": "590",
                "code": "GP"
            },
            {
                "id": 88,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gu.png",
                "englishName": "Guam",
                "phoneAreaCode": "1",
                "code": "GU"
            },
            {
                "id": 89,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gt.png",
                "englishName": "Guatemala",
                "phoneAreaCode": "502",
                "code": "GT"
            },
            {
                "id": 90,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gg.png",
                "englishName": "Guernsey",
                "phoneAreaCode": "44",
                "code": "GG"
            },
            {
                "id": 91,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gn.png",
                "englishName": "Guinea",
                "phoneAreaCode": "224",
                "code": "GN"
            },
            {
                "id": 92,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gw.png",
                "englishName": "Guinea-Bissau",
                "phoneAreaCode": "245",
                "code": "GW"
            },
            {
                "id": 93,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gy.png",
                "englishName": "Guyana",
                "phoneAreaCode": "592",
                "code": "GY"
            },
            {
                "id": 94,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ht.png",
                "englishName": "Haiti",
                "phoneAreaCode": "509",
                "code": "HT"
            },
            {
                "id": 95,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/hm.png",
                "englishName": "Heard Island and McDonald Islands",
                "phoneAreaCode": "61",
                "code": "HM"
            },
            {
                "id": 233,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/va.png",
                "englishName": "Holy See (Vatican City State)",
                "phoneAreaCode": "39",
                "code": "VA"
            },
            {
                "id": 96,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/hn.png",
                "englishName": "Honduras",
                "phoneAreaCode": "504",
                "code": "HN"
            },
            {
                "id": 97,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/hk.png",
                "englishName": "Hong Kong",
                "phoneAreaCode": "852",
                "code": "HK"
            },
            {
                "id": 98,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/hu.png",
                "englishName": "Hungary",
                "phoneAreaCode": "36",
                "code": "HU"
            },
            {
                "id": 99,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/is.png",
                "englishName": "Iceland",
                "phoneAreaCode": "354",
                "code": "IS"
            },
            {
                "id": 100,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/in.png",
                "englishName": "India",
                "phoneAreaCode": "91",
                "code": "IN"
            },
            {
                "id": 101,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/id.png",
                "englishName": "Indonesia",
                "phoneAreaCode": "62",
                "code": "ID"
            },
            {
                "id": 102,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/iq.png",
                "englishName": "Iraq",
                "phoneAreaCode": "964",
                "code": "IQ"
            },
            {
                "id": 103,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ie.png",
                "englishName": "Ireland",
                "phoneAreaCode": "353",
                "code": "IE"
            },
            {
                "id": 104,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/im.png",
                "englishName": "Isle of Man",
                "phoneAreaCode": "44",
                "code": "IM"
            },
            {
                "id": 105,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/il.png",
                "englishName": "Israel",
                "phoneAreaCode": "972",
                "code": "IL"
            },
            {
                "id": 106,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/it.png",
                "englishName": "Italy",
                "phoneAreaCode": "39",
                "code": "IT"
            },
            {
                "id": 107,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/jm.png",
                "englishName": "Jamaica",
                "phoneAreaCode": "1",
                "code": "JM"
            },
            {
                "id": 108,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/jp.png",
                "englishName": "Japan",
                "phoneAreaCode": "81",
                "code": "JP"
            },
            {
                "id": 109,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/je.png",
                "englishName": "Jersey",
                "phoneAreaCode": "44",
                "code": "JE"
            },
            {
                "id": 110,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/jo.png",
                "englishName": "Jordan",
                "phoneAreaCode": "962",
                "code": "JO"
            },
            {
                "id": 111,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/kz.png",
                "englishName": "Kazakhstan",
                "phoneAreaCode": "7",
                "code": "KZ"
            },
            {
                "id": 112,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ke.png",
                "englishName": "Kenya",
                "phoneAreaCode": "254",
                "code": "KE"
            },
            {
                "id": 113,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ki.png",
                "englishName": "Kiribati",
                "phoneAreaCode": "686",
                "code": "KI"
            },
            {
                "id": 114,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/kr.png",
                "englishName": "Korea, Republic of",
                "phoneAreaCode": "82",
                "code": "KR"
            },
            {
                "id": 115,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/kw.png",
                "englishName": "Kuwait",
                "phoneAreaCode": "965",
                "code": "KW"
            },
            {
                "id": 116,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/kg.png",
                "englishName": "Kyrgyzstan",
                "phoneAreaCode": "996",
                "code": "KG"
            },
            {
                "id": 117,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/la.png",
                "englishName": "Lao People's Democratic Republic",
                "phoneAreaCode": "856",
                "code": "LA"
            },
            {
                "id": 118,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lv.png",
                "englishName": "Latvia",
                "phoneAreaCode": "371",
                "code": "LV"
            },
            {
                "id": 119,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lb.png",
                "englishName": "Lebanon",
                "phoneAreaCode": "961",
                "code": "LB"
            },
            {
                "id": 120,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ls.png",
                "englishName": "Lesotho",
                "phoneAreaCode": "266",
                "code": "LS"
            },
            {
                "id": 121,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lr.png",
                "englishName": "Liberia",
                "phoneAreaCode": "231",
                "code": "LR"
            },
            {
                "id": 122,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ly.png",
                "englishName": "Libya",
                "phoneAreaCode": "218",
                "code": "LY"
            },
            {
                "id": 123,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/li.png",
                "englishName": "Liechtenstein",
                "phoneAreaCode": "423",
                "code": "LI"
            },
            {
                "id": 124,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lt.png",
                "englishName": "Lithuania",
                "phoneAreaCode": "370",
                "code": "LT"
            },
            {
                "id": 125,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lu.png",
                "englishName": "Luxembourg",
                "phoneAreaCode": "352",
                "code": "LU"
            },
            {
                "id": 126,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mo.png",
                "englishName": "Macao, SAR",
                "phoneAreaCode": "853",
                "code": "MO"
            },
            {
                "id": 127,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mk.png",
                "englishName": "Macedonia, the former Yugoslav Republic of",
                "phoneAreaCode": "389",
                "code": "MK"
            },
            {
                "id": 128,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mg.png",
                "englishName": "Madagascar",
                "phoneAreaCode": "261",
                "code": "MG"
            },
            {
                "id": 129,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mw.png",
                "englishName": "Malawi",
                "phoneAreaCode": "265",
                "code": "MW"
            },
            {
                "id": 130,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/my.png",
                "englishName": "Malaysia",
                "phoneAreaCode": "60",
                "code": "MY"
            },
            {
                "id": 131,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mv.png",
                "englishName": "Maldives",
                "phoneAreaCode": "960",
                "code": "MV"
            },
            {
                "id": 132,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ml.png",
                "englishName": "Mali",
                "phoneAreaCode": "223",
                "code": "ML"
            },
            {
                "id": 133,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mt.png",
                "englishName": "Malta",
                "phoneAreaCode": "356",
                "code": "MT"
            },
            {
                "id": 134,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mh.png",
                "englishName": "Marshall Islands",
                "phoneAreaCode": "692",
                "code": "MH"
            },
            {
                "id": 135,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mq.png",
                "englishName": "Martinique",
                "phoneAreaCode": "596",
                "code": "MQ"
            },
            {
                "id": 136,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mr.png",
                "englishName": "Mauritania",
                "phoneAreaCode": "222",
                "code": "MR"
            },
            {
                "id": 137,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mu.png",
                "englishName": "Mauritius",
                "phoneAreaCode": "230",
                "code": "MU"
            },
            {
                "id": 138,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/yt.png",
                "englishName": "Mayotte",
                "phoneAreaCode": "262",
                "code": "YT"
            },
            {
                "id": 139,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mx.png",
                "englishName": "Mexico",
                "phoneAreaCode": "52",
                "code": "MX"
            },
            {
                "id": 140,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/fm.png",
                "englishName": "Micronesia, Federated States of",
                "phoneAreaCode": "691",
                "code": "FM"
            },
            {
                "id": 141,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/md.png",
                "englishName": "Moldova, Republic of",
                "phoneAreaCode": "373",
                "code": "MD"
            },
            {
                "id": 142,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mc.png",
                "englishName": "Monaco",
                "phoneAreaCode": "377",
                "code": "MC"
            },
            {
                "id": 143,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mn.png",
                "englishName": "Mongolia",
                "phoneAreaCode": "976",
                "code": "MN"
            },
            {
                "id": 144,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/me.png",
                "englishName": "Montenegro",
                "phoneAreaCode": "382",
                "code": "ME"
            },
            {
                "id": 145,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ms.png",
                "englishName": "Montserrat",
                "phoneAreaCode": "1",
                "code": "MS"
            },
            {
                "id": 146,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ma.png",
                "englishName": "Morocco",
                "phoneAreaCode": "212",
                "code": "MA"
            },
            {
                "id": 147,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mz.png",
                "englishName": "Mozambique",
                "phoneAreaCode": "258",
                "code": "MZ"
            },
            {
                "id": 148,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mm.png",
                "englishName": "Myanmar",
                "phoneAreaCode": "95",
                "code": "MM"
            },
            {
                "id": 149,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/na.png",
                "englishName": "Namibia",
                "phoneAreaCode": "264",
                "code": "NA"
            },
            {
                "id": 150,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/nr.png",
                "englishName": "Nauru",
                "phoneAreaCode": "674",
                "code": "NR"
            },
            {
                "id": 151,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/np.png",
                "englishName": "Nepal",
                "phoneAreaCode": "977",
                "code": "NP"
            },
            {
                "id": 152,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/nl.png",
                "englishName": "Netherlands",
                "phoneAreaCode": "31",
                "code": "NL"
            },
            {
                "id": 153,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/nc.png",
                "englishName": "New Caledonia",
                "phoneAreaCode": "687",
                "code": "NC"
            },
            {
                "id": 154,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/nz.png",
                "englishName": "New Zealand",
                "phoneAreaCode": "64",
                "code": "NZ"
            },
            {
                "id": 155,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ni.png",
                "englishName": "Nicaragua",
                "phoneAreaCode": "505",
                "code": "NI"
            },
            {
                "id": 156,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ne.png",
                "englishName": "Niger",
                "phoneAreaCode": "227",
                "code": "NE"
            },
            {
                "id": 157,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ng.png",
                "englishName": "Nigeria",
                "phoneAreaCode": "234",
                "code": "NG"
            },
            {
                "id": 158,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/nu.png",
                "englishName": "Niue",
                "phoneAreaCode": "683",
                "code": "NU"
            },
            {
                "id": 159,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/nf.png",
                "englishName": "Norfolk Island",
                "phoneAreaCode": "672",
                "code": "NF"
            },
            {
                "id": 160,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mp.png",
                "englishName": "Northern Mariana Islands",
                "phoneAreaCode": "1",
                "code": "MP"
            },
            {
                "id": 161,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/no.png",
                "englishName": "Norway",
                "phoneAreaCode": "47",
                "code": "NO"
            },
            {
                "id": 162,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/om.png",
                "englishName": "Oman",
                "phoneAreaCode": "968",
                "code": "OM"
            },
            {
                "id": 163,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pk.png",
                "englishName": "Pakistan",
                "phoneAreaCode": "92",
                "code": "PK"
            },
            {
                "id": 164,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pw.png",
                "englishName": "Palau",
                "phoneAreaCode": "680",
                "code": "PW"
            },
            {
                "id": 165,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ps.png",
                "englishName": "Palestinian Territory, Occupied",
                "phoneAreaCode": "970",
                "code": "PS"
            },
            {
                "id": 166,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pa.png",
                "englishName": "Panama",
                "phoneAreaCode": "507",
                "code": "PA"
            },
            {
                "id": 167,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pg.png",
                "englishName": "Papua New Guinea",
                "phoneAreaCode": "675",
                "code": "PG"
            },
            {
                "id": 168,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/py.png",
                "englishName": "Paraguay",
                "phoneAreaCode": "595",
                "code": "PY"
            },
            {
                "id": 169,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pe.png",
                "englishName": "Peru",
                "phoneAreaCode": "51",
                "code": "PE"
            },
            {
                "id": 170,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ph.png",
                "englishName": "Philippines",
                "phoneAreaCode": "63",
                "code": "PH"
            },
            {
                "id": 171,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pn.png",
                "englishName": "Pitcairn",
                "phoneAreaCode": "870",
                "code": "PN"
            },
            {
                "id": 172,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pl.png",
                "englishName": "Poland",
                "phoneAreaCode": "48",
                "code": "PL"
            },
            {
                "id": 173,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pt.png",
                "englishName": "Portugal",
                "phoneAreaCode": "351",
                "code": "PT"
            },
            {
                "id": 174,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pr.png",
                "englishName": "Puerto Rico",
                "phoneAreaCode": "1",
                "code": "PR"
            },
            {
                "id": 175,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/qa.png",
                "englishName": "Qatar",
                "phoneAreaCode": "974",
                "code": "QA"
            },
            {
                "id": 176,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/re.png",
                "englishName": "Réunion",
                "phoneAreaCode": "262",
                "code": "RE"
            },
            {
                "id": 177,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ro.png",
                "englishName": "Romania",
                "phoneAreaCode": "40",
                "code": "RO"
            },
            {
                "id": 178,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ru.png",
                "englishName": "Russian Federation",
                "phoneAreaCode": "7",
                "code": "RU"
            },
            {
                "id": 179,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/rw.png",
                "englishName": "Rwanda",
                "phoneAreaCode": "250",
                "code": "RW"
            },
            {
                "id": 180,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/bl.png",
                "englishName": "Saint Barthélemy",
                "phoneAreaCode": "590",
                "code": "BL"
            },
            {
                "id": 181,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sh.png",
                "englishName": "Saint Helena, Ascension and Tristan da Cunha",
                "phoneAreaCode": "290",
                "code": "SH"
            },
            {
                "id": 182,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/kn.png",
                "englishName": "Saint Kitts and Nevis",
                "phoneAreaCode": "1",
                "code": "KN"
            },
            {
                "id": 183,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lc.png",
                "englishName": "Saint Lucia",
                "phoneAreaCode": "1",
                "code": "LC"
            },
            {
                "id": 184,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/mf.png",
                "englishName": "Saint Martin (French part)",
                "phoneAreaCode": "590",
                "code": "MF"
            },
            {
                "id": 185,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/pm.png",
                "englishName": "Saint Pierre and Miquelon",
                "phoneAreaCode": "508",
                "code": "PM"
            },
            {
                "id": 186,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/vc.png",
                "englishName": "Saint Vincent and the Grenadines",
                "phoneAreaCode": "1",
                "code": "VC"
            },
            {
                "id": 187,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ws.png",
                "englishName": "Samoa",
                "phoneAreaCode": "685",
                "code": "WS"
            },
            {
                "id": 188,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sm.png",
                "englishName": "San Marino",
                "phoneAreaCode": "378",
                "code": "SM"
            },
            {
                "id": 189,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/st.png",
                "englishName": "Sao Tome and Principe",
                "phoneAreaCode": "239",
                "code": "ST"
            },
            {
                "id": 190,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sa.png",
                "englishName": "Saudi Arabia",
                "phoneAreaCode": "966",
                "code": "SA"
            },
            {
                "id": 191,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sn.png",
                "englishName": "Senegal",
                "phoneAreaCode": "221",
                "code": "SN"
            },
            {
                "id": 192,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/rs.png",
                "englishName": "Serbia",
                "phoneAreaCode": "381",
                "code": "RS"
            },
            {
                "id": 193,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sc.png",
                "englishName": "Seychelles",
                "phoneAreaCode": "248",
                "code": "SC"
            },
            {
                "id": 194,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sl.png",
                "englishName": "Sierra Leone",
                "phoneAreaCode": "232",
                "code": "SL"
            },
            {
                "id": 195,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sg.png",
                "englishName": "Singapore",
                "phoneAreaCode": "65",
                "code": "SG"
            },
            {
                "id": 196,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sx.png",
                "englishName": "Sint Maarten (Dutch part)",
                "phoneAreaCode": "1",
                "code": "SX"
            },
            {
                "id": 197,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sk.png",
                "englishName": "Slovakia",
                "phoneAreaCode": "421",
                "code": "SK"
            },
            {
                "id": 198,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/si.png",
                "englishName": "Slovenia",
                "phoneAreaCode": "386",
                "code": "SI"
            },
            {
                "id": 199,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sb.png",
                "englishName": "Solomon Islands",
                "phoneAreaCode": "677",
                "code": "SB"
            },
            {
                "id": 200,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/so.png",
                "englishName": "Somalia",
                "phoneAreaCode": "252",
                "code": "SO"
            },
            {
                "id": 201,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/za.png",
                "englishName": "South Africa",
                "phoneAreaCode": "27",
                "code": "ZA"
            },
            {
                "id": 202,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gs.png",
                "englishName": "South Georgia and the South Sandwich Islands",
                "phoneAreaCode": "500",
                "code": "GS"
            },
            {
                "id": 203,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ss.png",
                "englishName": "South Sudan",
                "phoneAreaCode": "211",
                "code": "SS"
            },
            {
                "id": 204,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/es.png",
                "englishName": "Spain",
                "phoneAreaCode": "34",
                "code": "ES"
            },
            {
                "id": 205,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/lk.png",
                "englishName": "Sri Lanka",
                "phoneAreaCode": "94",
                "code": "LK"
            },
            {
                "id": 206,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sr.png",
                "englishName": "Suriname",
                "phoneAreaCode": "597",
                "code": "SR"
            },
            {
                "id": 207,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sj.png",
                "englishName": "Svalbard and Jan Mayen",
                "phoneAreaCode": "47",
                "code": "SJ"
            },
            {
                "id": 208,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/sz.png",
                "englishName": "Swaziland",
                "phoneAreaCode": "268",
                "code": "SZ"
            },
            {
                "id": 209,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/se.png",
                "englishName": "Sweden",
                "phoneAreaCode": "46",
                "code": "SE"
            },
            {
                "id": 210,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ch.png",
                "englishName": "Switzerland",
                "phoneAreaCode": "41",
                "code": "CH"
            },
            {
                "id": 211,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tw.png",
                "englishName": "Taiwan",
                "phoneAreaCode": "886",
                "code": "TW"
            },
            {
                "id": 212,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tj.png",
                "englishName": "Tajikistan",
                "phoneAreaCode": "992",
                "code": "TJ"
            },
            {
                "id": 213,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tz.png",
                "englishName": "Tanzania, United Republic of",
                "phoneAreaCode": "255",
                "code": "TZ"
            },
            {
                "id": 214,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/th.png",
                "englishName": "Thailand",
                "phoneAreaCode": "66",
                "code": "TH"
            },
            {
                "id": 215,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tl.png",
                "englishName": "Timor-Leste",
                "phoneAreaCode": "670",
                "code": "TL"
            },
            {
                "id": 216,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tg.png",
                "englishName": "Togo",
                "phoneAreaCode": "228",
                "code": "TG"
            },
            {
                "id": 217,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tk.png",
                "englishName": "Tokelau",
                "phoneAreaCode": "690",
                "code": "TK"
            },
            {
                "id": 218,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/to.png",
                "englishName": "Tonga",
                "phoneAreaCode": "676",
                "code": "TO"
            },
            {
                "id": 219,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tt.png",
                "englishName": "Trinidad and Tobago",
                "phoneAreaCode": "1",
                "code": "TT"
            },
            {
                "id": 220,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tn.png",
                "englishName": "Tunisia",
                "phoneAreaCode": "216",
                "code": "TN"
            },
            {
                "id": 221,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tr.png",
                "englishName": "Turkey",
                "phoneAreaCode": "90",
                "code": "TR"
            },
            {
                "id": 222,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tm.png",
                "englishName": "Turkmenistan",
                "phoneAreaCode": "993",
                "code": "TM"
            },
            {
                "id": 223,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tc.png",
                "englishName": "Turks and Caicos Islands",
                "phoneAreaCode": "1",
                "code": "TC"
            },
            {
                "id": 224,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/tv.png",
                "englishName": "Tuvalu",
                "phoneAreaCode": "688",
                "code": "TV"
            },
            {
                "id": 225,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ug.png",
                "englishName": "Uganda",
                "phoneAreaCode": "256",
                "code": "UG"
            },
            {
                "id": 226,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ua.png",
                "englishName": "Ukraine",
                "phoneAreaCode": "380",
                "code": "UA"
            },
            {
                "id": 227,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ae.png",
                "englishName": "United Arab Emirates",
                "phoneAreaCode": "971",
                "code": "AE"
            },
            {
                "id": 228,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/gb.png",
                "englishName": "United Kingdom",
                "phoneAreaCode": "44",
                "code": "GB"
            },
            {
                "id": 229,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/us.png",
                "englishName": "United States",
                "phoneAreaCode": "1",
                "code": "US"
            },
            {
                "id": 230,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/uy.png",
                "englishName": "Uruguay",
                "phoneAreaCode": "598",
                "code": "UY"
            },
            {
                "id": 231,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/uz.png",
                "englishName": "Uzbekistan",
                "phoneAreaCode": "998",
                "code": "UZ"
            },
            {
                "id": 232,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/vu.png",
                "englishName": "Vanuatu",
                "phoneAreaCode": "678",
                "code": "VU"
            },
            {
                "id": 234,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ve.png",
                "englishName": "Venezuela, Bolivarian Republic of",
                "phoneAreaCode": "58",
                "code": "VE"
            },
            {
                "id": 235,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/vn.png",
                "englishName": "Viet Nam",
                "phoneAreaCode": "84",
                "code": "VN"
            },
            {
                "id": 236,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/vg.png",
                "englishName": "Virgin Islands, British",
                "phoneAreaCode": "1",
                "code": "VG"
            },
            {
                "id": 237,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/vi.png",
                "englishName": "Virgin Islands, U.S.",
                "phoneAreaCode": "1",
                "code": "VI"
            },
            {
                "id": 238,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/wf.png",
                "englishName": "Wallis and Futuna",
                "phoneAreaCode": "681",
                "code": "WF"
            },
            {
                "id": 239,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/eh.png",
                "englishName": "Western Sahara",
                "phoneAreaCode": "212",
                "code": "EH"
            },
            {
                "id": 240,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/ye.png",
                "englishName": "Yemen",
                "phoneAreaCode": "967",
                "code": "YE"
            },
            {
                "id": 241,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/zm.png",
                "englishName": "Zambia",
                "phoneAreaCode": "260",
                "code": "ZM"
            },
            {
                "id": 242,
                "flag": "https://oss.swooshtransfer.cn/flag/png250px/zw.png",
                "englishName": "Zimbabwe",
                "phoneAreaCode": "263",
                "code": "ZW"
            }
        ]
    },
}