import { h, resolveComponent, App } from 'vue'

import {
  Router,
  createRouter,
  RouteRecordRaw,
  createWebHistory,
} from 'vue-router'

// export router instance
export const router: Router = createRouter({
  history: createWebHistory(import.meta.env.PROD ? '' : ''),
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes: [
    {
      path: '',
      name: 'App',
      component: { render: () => h(resolveComponent('router-view'))},
      children: [],
    },
  ],
});

// Register Application Routes
const setRouter = (app: App) => {
  // Initialized Home Route
  const AppRoute: RouteRecordRaw[] = [
    {
      path: ':link(test_.*)',
      name: 'redirect',
      component: () => import('@/pages/redirect/index.vue'),
    },
    {
      path:'/test',
      name:'test',
      component:()=>import('@/pages/test/index.vue')
    },
    {
      path: '/pay/:sessionId',
      name: 'checkout',
      component: () => import('@/pages/checkout/index.vue'),
    },
    {
      path: '/wechat_pay/:sessionId',
      name: 'checkout1',
      component: () => import('@/pages/wechat/index.vue'),
    },
    {
      path: '/err',
      name: 'err',
      component: () => import('@/pages/err/error.vue'),
    },
    {
      path: '/paymentcode',
      name: 'paymentcode',
      component: () => import('@/pages/paymentcode/index.vue'),
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/pages/payment/index.vue'),
    },
    {
      path: '/test_payment/:paymentid',
      name: 'test_payment',
      component: () => import('@/pages/testpayment/index.vue'),
    },
    // {
    //   path:'/authorize',
    //   name:'authorize',
    //   component:()=>import('@/pages/authorize/index.vue'),
    // },
    {
      path: '/items',
      name: 'items',
      component: () => import('@/pages/items/index.vue'),
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/pages/privacy/index.vue'),
    },
    { path: '/:pathMatch(.*)',name:'notfound',component: () =>  import('@/pages/err/error.vue') },
  ];


  AppRoute.forEach((route) => router.addRoute('App', route));
  app.use(router)
}

export default setRouter;
