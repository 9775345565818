import {createI18n} from 'vue-i18n'
import LocalEN from './en'
import LocalTH from './th'
import LocalVI from './vi'
import LocalPT from './pt'
import LocalES from './es'
// 根据浏览器环境设置语言
const language = navigator.language.toLowerCase();
console.log(language);

let whitelanguage = ['en','th','vi','pt','es']
const SetLocal=()=>{
let response
switch(language){
 default:
  if(language.startsWith('pt')){
    response='pt'
  }else if(language.startsWith('es')){
    return 'es'
  }
  else if(whitelanguage.includes(language)){
    response=language
  }else{
    response='en'
  }
  break
}
return response || 'en'
}

const i18n = createI18n({
  legacy: false,
  locale: SetLocal(),
  fallbackLocale: 'en',
  messages: {
    en: {
      ...LocalEN
    },
    vi:{
      ...LocalVI
    },
    th:{
      ...LocalTH
    },
    pt:{
      ...LocalPT
    },
    es:{
      ...LocalES
    }

  },
});


export default i18n