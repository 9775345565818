import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import Vant from 'vant';
import 'vant/lib/index.css';

import setRouter from './router';
import './styles/index.scss'
import 'virtual:svg-icons-register'
import App from './App.vue'
import SvgIcon from '../src/components/SVG.vue'
import i18n from "./locales/index";

const Bootstrap = () => {
  const app = createApp(App);
  app.use(Vant)
  app.use(ElementPlus, { size: 'large', locale: zhCn })
  app.component('SvgIcon', SvgIcon)
  app.use(i18n)
  setRouter(app);
  app.mount('#app');
};

// Initialize The App
Bootstrap();
