export default {
    "Shopping": {
      "pay": "ชำระเงิน",
      "otherproduct": "สินค้าอื่น ๆ",
      "otherproducts": "สินค้าอื่น ๆ",
      "qty": "จำนวน",
      "patpat": "ซื้อบน PatPat",
      "each": "แต่ละ",
      "showall": "แสดงทั้งหมด",
      "items": "รายการ",
      "showless": "แสดงน้อยลง",
      "subtotal": "ยอดรวม",
      "totaldue": "ยอดรวมที่ต้องชำระ",
      "cashier": "พนักงานขาย",
      "and": "และ"
    },
    "LeftCard": {
      "back": "ย้อนกลับ",
      "poweredby": "ขับเคลื่อนโดย",
      "terms": "ข้อตกลง",
      "privacy": "ความเป็นส่วนตัว"
    },
    "TopCard": {
      "details": "รายละเอียด",
      "close": "ปิด"
    },
    "PaymentMethod": {
      "paymentmethod": "วิธีการชำระเงิน"
    },
    "ContactInformation": {
      "contactinformation": "ข้อมูลติดต่อ",
      "shippingaddress": "ที่อยู่สำหรับจัดส่ง",
      "country": "ประเทศ",
      "address1": "ที่อยู่บรรทัด 1",
      "address2": "ที่อยู่บรรทัด 2",
      "name": "ชื่อ",
      "billingaddress": "ที่อยู่สำหรับวางบิล",
      "phone": "โทรศัพท์",
      "email": "อีเมล",
      "FullName": "ชื่อเต็ม",
      "CPFNumber": "หมายเลข CPF"
    },
    "ButtunMessage": {
      "processing": "กำลังประมวลผล...",
      "wechatPC": "สร้างรหัส QR สำหรับ",
      "pay": "ชำระเงิน"
    },
    "ErrorMessage": {
      "confirm": "ยืนยัน",
      "FullNameerr": "ต้องระบุชื่อเต็ม",
      "Countryerr": "ต้องระบุประเทศ",
      "CPFNumbererr": "ต้องระบุหมายเลข CPF",
      "CPFIerr": "โปรดป้อนตามรูปแบบ: XXX.XXX.XXX-XX หรือ 11 ตัวเลขโดยไม่มีเครื่องหมายขีด",
      "CPFIVerr": "หมายเลข CPF ไม่ถูกต้อง โปรดตรวจสอบและป้อนใหม่",
    },
    "Pix": {
      "ScanPix": "สแกนหรือคัดลอกรหัสเพื่อชำระด้วย Pix",
      "tip": "หลังจากส่งคำสั่งซื้อของคุณ ให้สแกนรหัส QR ด้วยแอปธนาคารหรือคัดลอกรหัสเพื่อชำระเงิน",
      "Scanapp": "สแกนเพื่อชำระด้วยแอปธนาคารของคุณ",
      "pixcodetip": "วางรหัส Pix ภายใต้ 'คัดลอกและวาง Pix' ในพอร์ทัลการธนาคารอินเทอร์เน็ตหรือแอปธนาคารของคุณ",
      "pix_centip": "หลังจากการชำระเงินเรียบร้อยแล้ว ยังต้องมีการยืนยันจากผู้ให้บริการ กระบวนการนี้อาจใช้เวลาสูงสุดเปนาทีหลายนาที",
      "pix_copytip": "หรือคัดลอกรหัสเพื่อชำระเงิน",
      "pleasehtml": "โปรดชำระเงินใน",
    },
    "Copy": {
        "success": "คัดลอกลงคลิปบอร์ดแล้ว",
        "copy": "คัดลอก"
      },
      "CardInformation": {
        "CardInformation": "ข้อมูลบัตร",
        "NameOnCard": "ชื่อบนบัตร",
        "Name": "ชื่อ",
        "Nameerr": "ต้องระบุชื่อบนบัตร",
        "Expirationerr": "วันที่หมดอายุของบัตรของคุณไม่สมบูรณ์",
        "InvalidExpiration": "วันที่หมดอายุไม่ถูกต้อง",
        "pastExpiration": "วันที่หมดอายุของบัตรของคุณหมดอายุแล้ว",
        "Carderr": "หมายเลขบัตรของคุณไม่สมบูรณ์",
        "NumberErr": "หมายเลขบัตรของคุณไม่ถูกต้อง",
        "cvcerr": "รหัสความปลอดภัยของบัตรของคุณไม่สมบูรณ์",
        "InvalidCVCCode": "รหัส CVC ไม่ถูกต้อง"
    },
    "BillingAddress": {
        "BillingAddress": "ที่อยู่ในการเรียกเก็บเงิน",
        "BS": "ที่อยู่ในการเรียกเก็บเงินเหมือนกับการจัดส่ง",
        "AL1": "ที่อยู่บรรทัดที่ 1",
        "AL2": "ที่อยู่บรรทัดที่ 2",
        "City": "เมือง",
        "Postalcode": "รหัสไปรษณีย์",
        "State": "รัฐ",
        "Email": "อีเมล",
        "Phone": "โทรศัพท์",
        "Phonenumber": "หมายเลขโทรศัพท์"
    },
    "ShippingAddress": {
        "Name": "ชื่อ",
        "AL1": "ที่อยู่บรรทัดที่ 1",
        "AL2": "ที่อยู่บรรทัดที่ 2",
        "City": "เมือง",
        "Postalcode": "รหัสไปรษณีย์",
        "State": "รัฐ",
        "ShippingAddress": "ที่อยู่จัดส่ง"
    },
    "CountyCode": {
        "CountyUrl": []
    },
    "FormErr": {
      "l1": "ต้องระบุที่อยู่บรรทัดที่ 1",
      "l2": "ต้องระบุที่อยู่บรรทัดที่ 2",
      "Phoneerr": "ต้องระบุโทรศัพท์",
      "ie": "อีเมลไม่ถูกต้อง",
      "er": "ต้องระบุอีเมล",
      "cr": "ต้องระบุเมือง",
      "pr": "ต้องระบุรหัสไปรษณีย์",
      "sr": "ต้องระบุรัฐ",
      "nr": "ต้องระบุชื่อ",
      "cor": "ต้องระบุประเทศ"
  }
}